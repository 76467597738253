import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.css']
})
export class GenericDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { json: object, html: string; title: string; actionButtonText: string },
              dialogRef: MatDialogRef<GenericDialogComponent>
  ) {
  }


}
