import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';

// tslint:disable-next-line:max-line-length
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDividerModule} from '@angular/material/divider';
import {MatRadioModule} from '@angular/material/radio';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatListModule} from '@angular/material/list';
import {MAT_DATE_FORMATS, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DragulaModule} from 'ng2-dragula';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {LogLevel} from '@azure/msal-browser';
import {MsalInterceptor} from '@azure/msal-angular';
import {ChartsModule} from 'ng2-charts';
import {NgxMasonryModule} from 'ngx-masonry';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ImageSliderComponent} from './image-slider/image-slider.component';
import {MenuComponent} from './menu/menu.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
// import {HomepageComponent} from './homepage/homepage.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ItemListComponent } from './item-list/item-list.component';
import {CarouselComponent} from './carousel/carousel.component';
import {MatSliderModule} from '@angular/material/slider';
import {ItemDetailsComponent} from './item-list/item-details-dialog/item-details.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {GenericDialogComponent} from './generic-dialog/generic-dialog.component';
// import { CarouselModule } from 'primeng/carousel';


// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

@NgModule({
  declarations: [
    AppComponent,
      ImageSliderComponent,
      MenuComponent,
      // HomepageComponent,
      NavbarComponent,
      ItemListComponent,
      CarouselComponent,
      ItemDetailsComponent,
      ContactUsComponent,
      GenericDialogComponent

  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatToolbarModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        HttpClientModule,
        MatTableModule,
        MatSortModule,
        MatSnackBarModule,
        MatDialogModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatDividerModule,
        MatRadioModule,
        // CarouselModule,
        MatSidenavModule,
        MatListModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatTooltipModule,
        MatGridListModule,
        DragDropModule,
        DragulaModule,
        DragulaModule.forRoot(),
        MatRippleModule,
        NgxJsonViewerModule,
        ChartsModule,
        NgxMasonryModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatMenuModule,
        MatButtonToggleModule,
        RouterModule,
        AppRoutingModule,
        MatSliderModule
    ],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},

    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {


}
