import {Component, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css']
})
export class ImageSliderComponent implements OnInit {
  @Input() images: { url: string }[] = [];

  currentIndex = 0;
  imageChunks: { url: string }[][] = [];
  startX = 0;
  currentX = 0;
  isMobileView = false;

  ngOnInit(): void {
    this.chunkImages();
    setInterval(() => {
      this.next();
    }, 5000);
  }

  next(): void {
    this.currentIndex = (this.currentIndex + 1) % this.imageChunks.length;
  }

  prev(): void {
    this.currentIndex = (this.currentIndex - 1 + this.imageChunks.length) % this.imageChunks.length;
  }

  chunkImages(): void {
    const chunkSize = 3;
    for (let i = 0; i < this.images.length; i += chunkSize) {
      this.imageChunks.push(this.images.slice(i, i + chunkSize));
    }
  }



  handleTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX;
  }

  handleTouchMove(event: TouchEvent) {
    this.currentX = event.touches[0].clientX;
  }

  handleTouchEnd() {
    const deltaX = this.currentX - this.startX;
    if (deltaX > 50) {
      // Swipe right, go to previous slide
      this.prev();
    } else if (deltaX < -50) {
      // Swipe left, go to next slide
      this.next();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Check viewport width to determine if it's a mobile view
    this.isMobileView = window.innerWidth < 768; // Adjust the threshold as needed
  }
}
