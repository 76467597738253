<div class="menu-container">
    <div>
        <img src="assets/fav/red-logo.png" alt="The Dream Taste Logo" class="logo">

        <div class="container">
            <svg viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg" class="curve">
                <path id="curve" d="M10 50 Q100 90, 190 50" fill="transparent" stroke="transparent"/>
                <text class="curved-text">
                    <textPath href="#curve" startOffset="50%" text-anchor="middle">
                        Kitchen Of Quality
                    </textPath>
                </text>
            </svg>
        </div>

        <br>

        <div class="menu-buttons">
            <a routerLinkActive="active-link" routerLink="/menu/ar">عرض القائمة</a>
            <br>
            <a routerLinkActive="active-link" routerLink="/menu/en">View Menu</a>
        </div>
    </div>
</div>
<div class="fluid-container">
    <div id="spinner-global">
    </div>
    <div class="row">
        <div class="col-12" style="display: flex; width: 100%; justify-content: space-around; flex-direction: column-reverse; align-items: center;">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
