import {Component, HostListener, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ItemDetailsComponent} from '../item-list/item-details-dialog/item-details.component';
import {MatDialog} from '@angular/material/dialog';
import {GenericDialogComponent} from '../generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-menu-component',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {



  selectedCategory: string;
  // categories = ['Sandwiches', 'Drinks', 'Salads','Deserts', 'Meals', 'Add ons'];
  categories = ['Main Course', 'Sandwiches', 'Salads', 'Soups'];
  language = 'ar';
  showIcons = false;
  // ngOnInit(): void {
  // }

  constructor(private route: ActivatedRoute,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.language = params.get('language');
      console.log('params.get(\'language\')', params.get('language'));
      // this.switchLanguage(this.language);
    });
    this.selectedCategory = this.categories[0];

  }

  onCategorySelected(category: string) {
    this.selectedCategory = category;
  }



  toggleIcons() {
    this.showIcons = !this.showIcons;
  }

  // openPhoneDialog() {
  //   const json = '+962792223555';
  //   const html = '<div style="background-color: #f8f9fa; padding: 20px; border-radius: 8px; text-align: center; max-width: 400px; margin: 0 auto; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">\n' +
  //       '    <h3 style="color: #007bff; margin-bottom: 10px;">Need Assistance?</h3>\n' +
  //       '    <p style="color: #333; font-size: 18px;">We\'re here to help! Call us at:</p>\n' +
  //       '    <a href="tel:+962792223555" style="display: inline-block; margin-top: 10px; padding: 10px 20px; background-color: #28a745; color: #fff; text-decoration: none; border-radius: 5px; font-size: 20px;">\n' +
  //       '        <i class="fas fa-phone-alt" style="margin-right: 8px;"></i> +962792223555\n' +
  //       '    </a>\n' +
  //       '</div>';
  //   this.dialog.open(GenericDialogComponent, {width: '50%', data: {html}});
  // }
  protected readonly event = event;
}
