import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild} from '@angular/core';
// import { CarouselModule } from 'primeng/carousel';
// import * as Hammer from 'hammerjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, AfterViewInit{

  constructor(private elRef: ElementRef) {}
  @Output() categorySelected = new EventEmitter<string>();
  categories = ['Main Course','Sandwiches','Salads', 'Soups'];

  selectedCategory: string;
  responsiveOptions: any[] | undefined;
  selectedResponsiveOption: any;
  // @ViewChild('tabGroup') tabGroup: any;


  selectCategory(index: number) {
    this.selectedCategory = this.categories[index];
    this.categorySelected.emit(this.selectedCategory);
  }

  ngAfterViewInit() {
    // const hammer = new Hammer(this.elRef.nativeElement.querySelector('.tab-container'));
    // hammer.on('swipeleft', () => this.swipe('left'));
    // hammer.on('swiperight', () => this.swipe('right'));
  }

  swipe(direction: string) {
    // const index = this.tabGroup.selectedIndex;
    // if (direction === 'left') {
    //   this.tabGroup.selectedIndex = (index + 1) % this.tabGroup._tabs.length;
    // } else if (direction === 'right') {
    //   this.tabGroup.selectedIndex = (index - 1 + this.tabGroup._tabs.length) % this.tabGroup._tabs.length;
    // }
  }

  // ngAfterViewInit() {
  //   const hammer = new Hammer(this.el.nativeElement.querySelector('.carousel'));
  //   hammer.on('swipeleft', () => this.next());
  //   hammer.on('swiperight', () => this.prev());
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateCarouselConfig();
  }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: '1400px',
        numVisible: 3,
        width: '99%',
        numScroll: 1
      },
      {
        breakpoint: '991px',
        numVisible: 3,
        width: '100%',
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 3,
        width: 'min-content',
        maxWidth: '90%',
        numScroll: 1
      }
    ];
    this.updateCarouselConfig();
  }

  updateCarouselConfig() {
    const width = window.innerWidth;
    console.log('width', width);
    this.selectedResponsiveOption = this.responsiveOptions.reduce((prev, curr) => {
      return parseInt(curr.breakpoint.replace('px', ''), 10) < parseInt(prev.breakpoint.replace('px', ''), 10) ? curr : prev;
    });
    // document.getElementsByClassName('mat-tab-c')[0].;
  }
}
