
<div class="slider"
     (touchstart)="handleTouchStart($event)"
     (touchmove)="handleTouchMove($event)"
     (touchend)="handleTouchEnd()">
    <div class="slide" *ngFor="let chunk of imageChunks; let i = index" [class.active]="i === currentIndex">
        <div class="controls-left" *ngIf="!isMobileView">
            <button (click)="prev()" class="prev">&#10094;</button>
        </div>
        <div class="image-container">
            <img *ngFor="let image of chunk" [src]="image.url" [style.width.%]="100/chunk.length-5" alt="Slide {{ i + 1 }}">
        </div>
        <div class="controls-right" *ngIf="!isMobileView">
            <button (click)="next()" class="next">&#10095;</button>
        </div>
    </div>
</div>


