import {Component, Input, HostListener,ElementRef, OnInit, AfterViewInit} from '@angular/core';
import * as Hammer from 'hammerjs';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit,AfterViewInit {
  @Input() items: any[] = [];
  currentIndex = 0;
  numVisible = 1; // Default value

  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 3,
      numScroll: 1
    }
  ];
  constructor(private el: ElementRef) {}
  ngOnInit() {
    this.updateCarouselConfig();
    window.addEventListener('resize', this.updateCarouselConfig.bind(this));
  }

  ngAfterViewInit() {
    const hammer = new Hammer(this.el.nativeElement.querySelector('.carousel'));
    hammer.on('swipeleft', () => this.next());
    hammer.on('swiperight', () => this.prev());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateCarouselConfig();
  }

  updateCarouselConfig() {
    const width = window.innerWidth;
    console.log('width', width);
    const minBreakpointOption = this.responsiveOptions.reduce((prev, curr) => {
      return parseInt(curr.breakpoint.replace('px', ''), 10) < parseInt(prev.breakpoint.replace('px', ''), 10) ? curr : prev;
    });
    for (const option of this.responsiveOptions) {
      const breakpointWidth = parseInt(option.breakpoint.replace('px', ''), 10);
      console.log('breakpoint width', breakpointWidth);
      if(width <= parseInt(minBreakpointOption.breakpoint.replace('px', ''), 10)){
        this.numVisible = minBreakpointOption.numVisible;
        break;
      }
      else if (width <= breakpointWidth) {
        this.numVisible = option.numVisible;
        break;
      } else {
        this.numVisible = 3; // Default value for larger screens
      }
    }
    this.currentIndex = 0;
  }

  get transform(): string {
    console.log('this.currentIndex * (100 / this.numVisible)',this.currentIndex * (100 / this.numVisible));
    console.log('TRANS currentIndex',this.currentIndex);
    console.log('TRANS CURR',this.numVisible);
    console.log('??????????????????????????????????????????????????');
    return `translateX(-${(this.currentIndex) * (100 / this.numVisible)}%)`;
  }

  get visibleItems(): any[] {
    return this.items.slice(this.currentIndex, this.currentIndex + this.numVisible);
  }

  prev(): void {
    if (this.currentIndex > 0) {
      this.currentIndex -= 1;
    } else {
      this.currentIndex = this.items.length - this.numVisible;
    }
  }

  next(): void {
    if (this.currentIndex < (this.items.length - this.numVisible)-1) {
      this.currentIndex += 1;
    } else {
      this.currentIndex = 0;
    }
  }
}
