<div class="contact-us">
    <div class="main-icon" (click)="toggleIcons()">
        <i class="fas fa-share-alt"></i>
    </div>
    <div class="social-icons" [class.expanded]="iconsExpanded">
        <a href="https://facebook.com" target="_blank" aria-label="Facebook">
            <i class="fab fa-facebook"></i>
        </a>
        <a href="https://twitter.com" target="_blank" aria-label="Twitter">
            <i class="fab fa-twitter"></i>
        </a>
        <a href="https://instagram.com" target="_blank" aria-label="Instagram">
            <i class="fab fa-instagram"></i>
        </a>
        <a href="https://linkedin.com" target="_blank" aria-label="LinkedIn">
            <i class="fab fa-linkedin"></i>
        </a>
    </div>
</div>
