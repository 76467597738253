import { Component, Input, OnChanges } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ItemDetailsComponent} from './item-details-dialog/item-details.component';
import {Direction} from '@angular/cdk/bidi';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css']
})
export class ItemListComponent implements OnChanges {
  @Input() selectedCategory: string;
  @Input() selectedLanguage: string;

  filteredItems = [];

  itemsV2 = [
    {
      id: 1,
      name: {
        en: 'Kabseh With Chicken',
        ar: 'كبسة بالدجاج'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.25,
      image: 'assets/meals-images/Meals Photos/M01-Kabseh.jpg',
      description: {
        en: 'Chicken Thigh With Basmati Rice & Dagous sauce.',
        ar: 'فخذ دجاج، أرز بسمتي مع صوص الدقوس'
      },
      addOns: [
        {
          name: {
            en: 'Add Chicken Breast',
            ar: 'إضافة صدر دجاج بدل الفخذ'
          },
          price: 0.50
        },
        {
          name: {
            en: 'Add Yogurt small 150-200g',
            ar: 'إضافة لبن صغير 150-200غم'
          },
          price: 0.50
        },
        {
          name: {
            en: 'Add Arabic Salad',
            ar: 'إضافة سلطة عربية صغيرة'
          },
          price: 1.50
        }
      ],
      available: true
    },
    {
      id: 2,
      name: {
        en: 'Butter Chicken',
        ar: 'دجاج بالزبدة'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.50,
      image: 'assets/meals-images/Meals Photos/M02- Butter Chicken.jpg',
      description: {
        en: 'Chicken Cubes, Creamy Sauce With Basmati Rice.',
        ar: 'مكعبات الدجاج، صلصة كريمة مع أرز البسمتي'
      },
      addOns: [],
      available: true
    },
    {
      id: 3,
      name: {
        en: 'Beef Stroganoff',
        ar: 'بيف ستروغانوف'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 6.75,
      image: 'assets/meals-images/Meals Photos/M03- Beaf Srognoff.jpg',
      description: {
        en: 'Shredded Beef meat, Mushroom, Creamy Sauce With Rice.',
        ar: 'شرائح اللحم البقري المقطع، فطر، صلصة كريمية، مع الأرز'
      },
      addOns: [],
      available: true
    },
    {
      id: 4,
      name: {
        en: 'Chicken Mushroom With Lemon',
        ar: 'دجاج بالفطر والليمون'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.75,
      image: 'assets/meals-images/Meals Photos/M04- Chicken Mushroom With Lemon.jpg',
      description: {
        en: 'Chicken Thigh, Mushroom, Lemon, Garlic, Herbs, Soft Sauce With Rice.',
        ar: 'فخذ دجاج، فطر، ليمون، ثوم، أعشاب، صلصة خفيفة مع الأرز'
      },
      addOns: [
        {
          name: {
            en: 'Add Chicken Breast',
            ar: 'إضافة صدر دجاج بدل الفخذ'
          },
          price: 0.50
        }
      ],
      available: true
    },
    {
      id: 5,
      name: {
        en: 'Honey Chili Chicken',
        ar: 'الدجاج الحار بالعسل'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.50,
      image: 'assets/meals-images/Meals Photos/M05- Honey Chili Chicken (THAI).jpg',
      description: {
        en: 'Chicken Thigh, Honey, Chili, Spices, Green Onion, Garlic, Sautéed Vegetables With Rice.',
        ar: 'فخذ دجاج، عسل، فلفل حار، بهارات، بصل أخضر، ثوم، خضار سوتيه مع الأرز'
      },
      addOns: [
        {
          name: {
            en: 'Add Chicken Breast',
            ar: 'إضافة صدر دجاج بدل الفخذ'
          },
          price: 0.50
        }
      ],
      available: true
    },
    {
      id: 6,
      name: {
        en: 'White Cream Chicken Mushroom',
        ar: 'دجاج بالفطر والكريمة البيضاء'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.50,
      image: 'assets/meals-images/Meals Photos/M06- White Cream Chicken Mushroom.jpg',
      description: {
        en: 'Boneless Chicken, Fresh Mushroom, Fresh Cream & Sautéed Vegetables.',
        ar: 'دجاج مسحب، فطر طازج، كريمة طازجة، خضار سوتيه مع الأرز'
      },
      addOns: [],
      available: true
    },
    {
      id: 7,
      name: {
        en: 'Healthy Boneless Chicken With Vegetables',
        ar: 'دجاج مسحب بالأعشاب والخضار'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.50,
      image: 'assets/meals-images/Meals Photos/M07- Healthy  Boneless Chicken With Vegs.jpg',
      description: {
        en: 'Boneless Chicken Breast, Herbs, Spices & Sautéed Vegetables With Rice.',
        ar: 'صدر الدجاج المسحب، أعشاب، بهارات، خضار سوتيه مع الأرز'
      },
      addOns: [],
      available: true
    },
    {
      id: 8,
      name: {
        en: 'Steak with Vegetables',
        ar: 'ستيك بالخضار'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 8.00,
      image: 'assets/meals-images/08- Steak With Greavy.jpg',
      description: {
        en: 'Beef fillet slices, brown steak sauce, stir-fried vegetables with mashed potatoes.',
        ar: 'شرائح لحم الفيليه، صوص الستيك البني، خضار سوتيه مع البطاطا المهروسة'
      },
      addOns: [],
      available: false
    },
    {
      id: 9,
      name: {
        en: 'Noodles with Chicken and Vegetables',
        ar: 'معكرونة النودلز بالدجاج والخضار'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.00,
      image: 'assets/meals-images/09- Chinese Noodles.jpg',
      description: {
        en: 'Chicken slices, assorted vegetables with noodles.',
        ar: 'شرائح الدجاج المقطع، خضار منوعة مع معكرونة النودلز'
      },
      addOns: [],
      available: false
    },
    {
      id: 10,
      name: {
        en: 'Noodles with Vegetables',
        ar: 'معكرونة النودلز بالخضار'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 4.25,
      image: '',
      description: {
        en: 'Assorted vegetables with noodles.',
        ar: 'خضار منوعة مع معكرونة النودلز'
      },
      addOns: [],
      available: false
    },
    {
      id: 11,
      name: {
        en: 'Ouzi with Chicken',
        ar: 'أوزي مع الدجاج'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.50,
      image: '',
      description: {
        en: 'Chicken thigh with rice, meat, peas, served with yogurt or Arabic salad.',
        ar: 'فخذ الدجاج مع الأرز باللحمة والبازيلا مع اللبن أو السلطة العربية'
      },
      addOns: [
        {
          name: {
            en: 'Add Chicken Breast',
            ar: 'إضافة صدر دجاج بدل الفخذ'
          },
          price: 0.50
        }
      ],
      available: false
    },
    {
      id: 12,
      name: {
        en: 'Bamia',
        ar: 'بامية'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 6.00,
      image: '',
      description: {
        en: 'Okra, meat, tomato with rice.',
        ar: 'بامية، لحمة، بندورة مع الأرز'
      },
      addOns: [],
      available: false
    },
    {
      id: 13,
      name: {
        en: 'Molokhia with Chicken',
        ar: 'ملوخية بالدجاج'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.00,
      image: '',
      description: {
        en: 'Chicken thigh, molokhia with rice.',
        ar: 'فخذ دجاج، ملوخية مع أرز'
      },
      addOns: [
        {
          name: {
            en: 'Add Chicken Breast',
            ar: 'إضافة صدر دجاج بدل الفخذ'
          },
          price: 0.50
        },
        {
          name: {
            en: 'Add Lemon with Hot Pepper',
            ar: 'إضافة ليمون مع فلفل حار'
          },
          price: 0.50
        }
      ],
      available: false
    },
    {
      id: 14,
      name: {
        en: 'Sheikh Al-Mahshi',
        ar: 'شيخ المحشي'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.00,
      image: '',
      description: {
        en: 'Zucchini, meat, onion, goat yogurt with rice.',
        ar: 'كوسا، لحمة، بصل، لبن نعاج مع الأرز'
      },
      addOns: [],
      available: false
    },
    {
      id: 15,
      name: {
        en: 'Kibbeh with Yogurt',
        ar: 'كبة باللبن'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.00,
      image: '',
      description: {
        en: 'Kibbeh, goat yogurt with rice.',
        ar: 'كبة، لبن نعاج مع الأرز'
      },
      addOns: [],
      available: false
    },
    {
      id: 16,
      name: {
        en: 'Shish Barak with Yogurt',
        ar: 'شيش برك باللبن'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.00,
      image: '',
      description: {
        en: 'Shish Barak, goat yogurt with rice.',
        ar: 'شيش برك، لبن نعاج مع الأرز'
      },
      addOns: [],
      available: false
    },
    {
      id: 17,
      name: {
        en: 'Stuffed Grape Leaves',
        ar: 'ورق دوالي'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 6.00,
      image: '',
      description: {
        en: 'Grape leaves, meat, zucchini.',
        ar: 'ورق دوالي، لحمة، كوسا'
      },
      addOns: [],
      available: false
    },
    {
      id: 18,
      name: {
        en: 'Yalanji',
        ar: 'يالنجي'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.00,
      image: 'assets/meals-images/18- Yalangi.jpg',
      description: {
        en: 'Grape leaves, parsley, onion, tomato, and rice.',
        ar: 'ورق دوالي، بقدونس، بصل، بندورة و أرز'
      },
      addOns: [],
      available: false
    },
    {
      id: 19,
      name: {
        en: 'Stuffed Cabbage',
        ar: 'ملفوف'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.50,
      image: '',
      description: {
        en: 'Cabbage, meat, and rice.',
        ar: 'ملفوف، لحمة و أرز'
      },
      addOns: [],
      available: false
    },
    {
      id: 20,
      name: {
        en: 'Beef Fajita',
        ar: 'فاهيتا باللحمة'
      },
      category: {
        en: 'Sandwiches',
        ar: 'السندويشات',
        id: 2
      },
      price: 5.00,
      image: 'assets/meals-images/Sandwiches Photos/SN09- Beef Fajita Sandwich.jpg',
      description: {
        en: 'Beef, Cheddar Cheese, Onion, Bell Pepper With Baguette Bread Or Tortilla Bread.',
        ar: 'لحمة، جبنة تشدر، بصل، فليفلة خضراء مع خبز الحمام أو التورتيلا'
      },
      addOns: [
        {
          name: {
            en: 'Add Guacamole Sauce',
            ar: 'إضافة صلصة الجواكامولي'
          },
          price: 1.00
        }
      ],
      available: true
    },
    {
      id: 21,
      name: {
        en: 'Chicken Fajita',
        ar: 'فاهيتا بالدجاج'
      },
      category: {
        en: 'Sandwiches',
        ar: 'السندويشات',
        id: 2
      },
      price: 4.25,
      image: 'assets/meals-images/Sandwiches Photos/SN08- Chicken Fajita Sandwich.jpg',
      description: {
        en: 'Chicken, Cheddar Cheese, Onion, Bell Pepper With Baguette Bread Or Tortilla Bread.',
        ar: 'دجاج، جبنة تشدر، بصل، فليفلة خضراء مع خبز الحمام أو التورتيلا'
      },
      addOns: [
        {
          name: {
            en: 'Add Guacamole Sauce',
            ar: 'إضافة صلصة الجواكامولي'
          },
          price: 1.00
        }
      ],
      available: true
    },
    {
      id: 22,
      name: {
        en: 'Musakhan with Chicken',
        ar: 'مسخن بالدجاج'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 6.75,
      image: 'assets/meals-images/22- Musakhan.jpg',
      description: {
        en: 'Half chicken, onion, olive oil with taboon bread.',
        ar: 'نصف دجاجة، بصل، زيت زيتون مع خبز الطابون'
      },
      addOns: [
        {
          name: {
            en: 'Add Chicken Thigh',
            ar: 'فخذ دجاجة، بصل، زيت زيتون مع خبز الطابون'
          },
          price: 5.25
        },
        {
          name: {
            en: 'Add Chicken Breast',
            ar: 'إضافة صدر دجاج بدل الفخذ'
          },
          price: 0.50
        }
      ],
      available: false
    },
    {
      id: 23,
      name: {
        en: 'Mansaf',
        ar: 'منسف'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 6.75,
      image: '',
      description: {
        en: 'Lamb meat with rice.',
        ar: 'لحم خاروف مع الأرز'
      },
      addOns: [],
      available: false
    },
    {
      id: 24,
      name: {
        en: 'Freekeh with Chicken',
        ar: 'فريكة بالدجاج'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.50,
      image: 'assets/meals-images/24- Freekeh.jpg',
      description: {
        en: 'Chicken thigh, freekeh with small yogurt.',
        ar: 'فخذ دجاجة، فريكة مع لبن صغير'
      },
      addOns: [
        {
          name: {
            en: 'Add Chicken Breast',
            ar: 'إضافة صدر دجاج بدل الفخذ'
          },
          price: 0.50
        }
      ],
      available: false
    },
    {
      id: 25,
      name: {
        en: 'Makloubeh',
        ar: 'مقلوبة'
      },
      category: {
        en: 'Main Course',
        ar: 'الوجبات الرئيسية',
        id: 1
      },
      price: 5.00,
      image: '',
      description: {
        en: 'Chicken thigh, mixed vegetables with rice.',
        ar: 'فخذ دجاجة، خضار مشكلة مع الأرز'
      },
      addOns: [
        {
          name: {
            en: 'Add Chicken Breast',
            ar: 'إضافة صدر دجاج بدل الفخذ'
          },
          price: 0.50
        }
      ],
      available: false
    },
    {
      id: 26,
      name: {
        en: 'Halloumi Sandwich',
        ar: 'ساندويش حلوم'
      },
      category: {
        en: 'Sandwiches',
        ar: 'السندويشات',
        id: 2
      },
      price: 3.75,
      image: 'assets/meals-images/Sandwiches Photos/SN01- Halloumi Sandwich.jpg',
      description: {
        en: 'Halloumi Cheese , Tomato, Pesto, Black Olive & Rocca With Baguette Bread.',
        ar: 'جبنة حلوم، بندورة، بيستو، زيتون أسود، جرجير مع خبز باجيت'
      },
      addOns: [],
      available: true
    },
    {
      id: 27,
      name: {
        en: 'Turkey Sandwich',
        ar: 'ساندويش تركي'
      },
      category: {
        en: 'Sandwiches',
        ar: 'السندويشات',
        id: 2
      },
      price: 4.00,
      image: 'assets/meals-images/Sandwiches Photos/SN02-Turkey Sandwich.jpg',
      description: {
        en: 'Turkey Breast Cutlets, Lettuce, Cheddar Cheese, Honey-mustard With Baguette Bread.',
        ar: 'شرائح صدر التيركي، خس، جبنة تشدر، مايونيز مع خبز الحمام'
      },
      addOns: [],
      available: true
    },
    {
      id: 28,
      name: {
        en: 'Roast Beef Sandwich',
        ar: 'ساندويش روست بيف'
      },
      category: {
        en: 'Sandwiches',
        ar: 'السندويشات',
        id: 2
      },
      price: 4.00,
      image: 'assets/meals-images/Sandwiches Photos/SN03- Roast Beef Sandwich.jpg',
      description: {
        en: 'Roast Beef Cutlets, Cheddar Cheese, Lettuce, Mayonnaise, Black Olive With Baguette Bread.',
        ar: 'شرائح الروست بيف، خس، مايونيز، زيتون أسود مع خبز الحمام'
      },
      addOns: [],
      available: true
    },
    {
      id: 29,
      name: {
        en: 'Omelet Sandwich',
        ar: 'ساندويش أومليت'
      },
      category: {
        en: 'Sandwiches',
        ar: 'السندويشات',
        id: 2
      },
      price: 3.75,
      image: 'assets/meals-images/Sandwiches Photos/SN04-Omleete Sandwich.jpg',
      description: {
        en: 'Egg, Tomato, Lettuce, Ketchup With Baguette Bread.',
        ar: 'بيض، بندورة، خس، كاتشب مع خبز الحمام'
      },
      addOns: [],
      available: true
    },
    {
      id: 30,
      name: {
        en: 'Avocado Omelet Sandwich',
        ar: 'ساندويش أومليت مع الأفوكادو'
      },
      category: {
        en: 'Sandwiches',
        ar: 'السندويشات',
        id: 2
      },
      price: 4.50,
      image: 'assets/meals-images/Sandwiches Photos/SN05- Omleete & Avocado Sandwich.jpg',
      description: {
        en: 'Egg, Tomato, Lettuce & Avocado With Baguette Bread.',
        ar: 'بيض، بندورة، خس، أفوكادو مع خبز الحمام'
      },
      addOns: [],
      available: true
    },
    {
      id: 31,
      name: {
        en: 'Zinger Sandwich',
        ar: 'ساندويش زينجر'
      },
      category: {
        en: 'Sandwiches',
        ar: 'السندويشات',
        id: 2
      },
      price: 4.50,
      image: 'assets/meals-images/Sandwiches Photos/SN07- Zinger Sandwich.jpg',
      description: {
        en: 'Zinger, Cheddar Cheese, Turkey, Mayonnaise, Jalapeno & Lettuce With Baguette Bread.',
        ar: 'زنجر، جبنة تشدر، تيركي، مايونيز، فلفل هالابينو، خس مع خبز الحمام'
      },
      addOns: [],
      available: true
    },
    {
      id: 32,
      name: {
        en: 'Caesar Salad',
        ar: 'سلطة السيزر'
      },
      category: {
        en: 'Salads',
        ar: 'السلطات',
        id: 3
      },
      price: 4.25,
      image: 'assets/meals-images/Salads Photos/SD01- Caeser Salad.jpg',
      description: {
        en: 'Lettuce, Parmesan Cheese & Special Caesar Dressing Sauce.',
        ar: 'خس، جبنة البارمازان مع صلصة السيزر الخاصة'
      },
      addOns: [],
      available: true
    },
    {
      id: 33,
      name: {
        en: 'Caesar Salad With Chicken',
        ar: 'سلطة السيزر بالدجاج'
      },
      category: {
        en: 'Salads',
        ar: 'السلطات',
        id: 3
      },
      price: 5.25,
      image: 'assets/meals-images/Salads Photos/SD02- Caeser Salad with Chicken.jpg',
      description: {
        en: 'Lettuce, Chicken, Parmesan Cheese & Caesar Dressing Sauce.',
        ar: 'خس، دجاج، جبنة البارمازان مع صلصة السيزر الخاصة'
      },
      addOns: [],
      available: true
    },
    {
      id: 34,
      name: {
        en: 'Rocca Salad',
        ar: 'سلطة الجرجير'
      },
      category: {
        en: 'Salads',
        ar: 'السلطات',
        id: 3
      },
      price: 4.30,
      image: 'assets/meals-images/Salads Photos/SD03- Rocca Salad.jpg',
      description: {
        en: 'Rocca, Mushroom, Cherry Tomato, Feta Cheese With Dressing Sauce & Walnut.',
        ar: 'جرجير، فطر، بندورة كرزية، جبنة فيتا، مع الصلصة الخاصة والجوز'
      },
      addOns: [],
      available: true
    },
    {
      id: 35,
      name: {
        en: 'Arabic Salad',
        ar: 'سلطة عربية'
      },
      category: {
        en: 'Salads',
        ar: 'السلطات',
        id: 3
      },
      price: 1.50,
      image: 'assets/meals-images/Salads Photos/SD05- Arabic Salad.jpg',
      description: {
        en: 'Tomato, Cucumber, Lettuce, Parsley, Garlic & Special Dressing Sauce.',
        ar: 'بندورة، خيار، خس، بقدونس، ثوم مع الصلصة الخاصة'
      },
      addOns: [],
      available: true
    },
    {
      id: 36,
      name: {
        en: 'Fattoush',
        ar: 'فتوش'
      },
      category: {
        en: 'Salads',
        ar: 'السلطات',
        id: 3
      },
      price: 3.50,
      image: 'assets/meals-images/Salads Photos/SD06- Fattoush.jpg',
      description: {
        en: 'Tomato, Cucumber, Lettuce, Parsley, Garlic, Bakleh, Green Onion & Dressing Sauce & Toasted Bread.',
        ar: 'بندورة، خيار، خس، بقدونس، ثوم، بقلة، بصل أخضر مع الصلصة والخبز المحمص'
      },
      addOns: [],
      available: true
    },
    {
      id: 37,
      name: {
        en: 'Shawarma Salad',
        ar: 'سلطة الشاورما'
      },
      category: {
        en: 'Salads',
        ar: 'السلطات',
        id: 3
      },
      price: 5.50,
      image: 'assets/meals-images/Salads Photos/SD08- Shawarma Salad.jpg',
      description: {
        en: 'Chicken Shawarma , Tomato, Lettuce. Onion, Rocca, Pickles & Dressing Sauce.',
        ar: 'شاورما دجاج، بندورة، خس، بصل، جرجير، مخلل والصلصة الخاصة'
      },
      addOns: [],
      available: true
    },
    {
      id: 38,
      name: {
        en: 'Crunchy Thai Peanut Salad',
        ar: 'سلطة الفول السوداني التايلاندية المقرمشة'
      },
      category: {
        en: 'Salads',
        ar: 'السلطات',
        id: 3
      },
      price: 6.00,
      image: 'assets/meals-images/Salads Photos/SD09- Crunchy Thai Peanut Salad.jpg',
      description: {
        en: 'Cabbage, Lettuce, Carrots, Green Onion, Garlic, Almond With Fried Noodles & Peanut Dressing Sauce.',
        ar: 'ملفوف، خس، جزر، بصل أخضر، ثوم، لوز، نودلز مقلية مع صلصة الفول السوداني'
      },
      addOns: [],
      available: true
    },
    {
      id: 39,
      name: {
        en: 'Healthy Roast Beef & Turkey Sandwich',
        ar: 'الساندویش الصحي بالروست بیف و التیركي'
      },
      category: {
        en: 'Sandwiches',
        ar: 'السندويشات',
        id: 2
      },
      price: 5.50,
      image: 'assets/meals-images/Sandwiches Photos/SN11- Roast Beef & Turkey Sandwich.jpg',
      description: {
        en: 'Roast Beef, Turkey, Cheddar Cheese, Lettuce, Mayonnaise, Avocado, Black Olive With Reef Bread',
        ar: 'شرحات الروست بیف، شرحات التیركي، جبنة تشدر، خس، مایونیز، أفوجادو، زیتون أسود مع خبز الریف'
      },
      addOns: [],
      available: true
    },
    {
      id: 40,
      name: {
        en: 'Vegetable Soup',
        ar: 'شوربة الخضار'
      },
      category: {
        en: 'Soups',
        ar: 'الشوربات',
        id: 4
      },
      price: 3.50,
      image: '',
      description: {
        en: 'Mixed vegetables in chicken broth.',
        ar: 'خضار مشكلة مع مرقة الدجاج'
      },
      addOns: [],
      available: false
    },
    {
      id: 41,
      name: {
        en: 'Mushroom Soup',
        ar: 'شوربة الفطر'
      },
      category: {
        en: 'Soups',
        ar: 'الشوربات',
        id: 4
      },
      price: 3.00,
      image: '',
      description: {
        en: 'Mushrooms, fresh cream in chicken broth.',
        ar: 'فطر، كريمة طازجة مع مرقة الدجاج'
      },
      addOns: [],
      available: false
    },
    {
      id: 42,
      name: {
        en: 'Corn Soup',
        ar: 'شوربة الذرة'
      },
      category: {
        en: 'Soups',
        ar: 'الشوربات',
        id: 4
      },
      price: 3.00,
      image: '',
      description: {
        en: 'Corn, fresh cream in chicken broth.',
        ar: 'ذرة، كريمة طازجة مع مرقة الدجاج'
      },
      addOns: [],
      available: false
    }
  ];


  textDirection: Direction = 'rtl';

   constructor(public dialog: MatDialog,) {
   }

  ngOnChanges() {
    this.textDirection = this.selectedLanguage === 'en' ? 'ltr' : 'rtl';
    this.filteredItems = this.itemsV2.filter(item => item.category.en === this.selectedCategory);
  }

  openImageDialog(imageSrc: string, imageAlt: string) {
    this.dialog.open(ItemDetailsComponent, {width: '100%', data: {imageSrc, imageAlt}});
  }
}
