import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';



@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.css']
})
export class ItemDetailsComponent implements OnInit {

  imageSrc: string;
  imageAlt: string;



  constructor(
      public dialogRef: MatDialogRef<ItemDetailsComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.imageSrc = data.imageSrc;
    this.imageAlt = data.imageAlt;
  }

  ngOnInit(): void {
    this.adjustDialogSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustDialogSize();
  }

  private adjustDialogSize() {
    const width = window.innerWidth < 600 ? '90%' : '600px';
    this.dialogRef.updateSize(width);
  }
}
