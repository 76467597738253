<h1 mat-dialog-title>
    {{ imageAlt }}
</h1>
<hr>
<div class="item-details-div" mat-dialog-content>
        <div class="item-details-img-div"  *ngIf="imageSrc">
            <img class="item-detail-image" [src]="imageSrc" [alt]="imageAlt"/>
        </div>
</div>

