import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MenuComponent} from './menu/menu.component';

const routes: Routes = [
  // {path: '', canActivate: [CarouselComponent], children: []},
  // {path: '', component: AppComponent},
  // {path: 'arabic', component: MenuComponent},
  // {path: 'english', component: MenuComponent},
  { path: 'menu/:language', component: MenuComponent },

];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes)
  ]
})
export class AppRoutingModule {

}
