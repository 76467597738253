<!--<div class="manooo">-->
<mat-toolbar class="navbar-toolbar">
    <mat-toolbar-row>
        <mat-tab-group id="matGroup" [style]="selectedResponsiveOption? 'width:'+selectedResponsiveOption.width : 'width:100%'" (selectedIndexChange)="selectCategory($event)">
            <mat-tab class="mat-tab-c" *ngFor="let category of categories; let i = index" [label]="category" [class.active]="category === selectedCategory">
            </mat-tab>
        </mat-tab-group>

<!--        <div class="items" style="z-index: -999">-->
<!--            <app-item-list-->
<!--                    [selectedLanguage]="'ar'"-->
<!--                    [selectedCategory]="selectedCategory">-->

<!--            </app-item-list>-->

<!--        </div>-->

    </mat-toolbar-row>

</mat-toolbar>


<!--</div>-->



<!--<div class="card">-->
<!--    <p-carousel-->
<!--            [value]="categories"-->
<!--            [numVisible]="3"-->
<!--            [numScroll]="2"-->
<!--            [responsiveOptions]="responsiveOptions">-->

<!--        <ng-template let-category pTemplate="item">-->
<!--            <div class="border-1 surface-border border-round m-2 p-3">-->

<!--                <div class="mb-3 font-medium">-->
<!--                    {{ category }}-->
<!--                </div>-->

<!--            </div>-->
<!--        </ng-template>-->
<!--    </p-carousel>-->
<!--</div>-->
