<div class="carousel-container" *ngIf="items && items.length">
    <div class="carousel" [style.transform]="transform">
        <div class="carousel-item" *ngFor="let item of visibleItems">
            <mat-card class="carousel-card">
                <mat-card-header>
                    <mat-card-title>{{ item }}</mat-card-title>
                </mat-card-header>
<!--                <img mat-card-image [src]="item.image" [alt]="item.title">-->
<!--                <mat-card-content>-->
<!--                    <p>{{ item }}</p>-->
<!--                </mat-card-content>-->
            </mat-card>
        </div>
    </div>
    <button class="carousel-control prev" (click)="prev()" aria-label="Previous">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <button class="carousel-control next" (click)="next()" aria-label="Next">
        <mat-icon>chevron_right</mat-icon>
    </button>
</div>
