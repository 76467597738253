<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">


<div class="app-container">

    <div class="menu-view">

        <app-navbar (categorySelected)="onCategorySelected($event)">
        </app-navbar>
        <app-item-list
                [selectedLanguage]="language"
                [selectedCategory]="selectedCategory">

        </app-item-list>
        <div class="contact-us-container">
            <div *ngIf="!showIcons" class="toggle-icon" (click)="toggleIcons()">
                <i class="fas fa-phone-alt fa-2x"></i>
<!--                <span class="contact-us-span">Contact us</span>-->

            </div>
            <div class="icons-container" *ngIf="showIcons">

                <a href="tel:+962792223555" target="_blank">
                <i class="fas fa-phone-alt"></i>
                </a>

                <a href="https://wa.me/+962795227755" target="_blank">
                    <i class="fab fa-whatsapp"></i>
                </a>

                <a href="https://www.facebook.com/share/b9P16rBBwyhiemUX/?mibextid=LQQJ4d" target="_blank">
                    <i class="fab fa-facebook"></i>
                </a>

                <a href="https://www.instagram.com/TheDream.Taste/" target="_blank">
                    <i class="fab fa-instagram"></i>
                </a>
                <a class="close-contact-us" *ngIf="showIcons" (click)="toggleIcons()">
                    <i class="fas fa-times"></i>
                </a>
            </div>
        </div>
        <!--        <a href="https://wa.me/+962795227755" target="_blank" class="whatsapp-icon">-->
        <!--            <img src="../../assets/fav/whatsapp_logo.png" alt="whatsapp-icon">-->
        <!--        </a>-->
        <!--        <br>-->
        <!--        <br>-->


    </div>
</div>

