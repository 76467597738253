// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: '//localhost:8080',
  dashboardUrl: 'https://localhost:4200/',
  isSingleInventory: false,
  defaultCurrency: 'USD',
  envKey: 'Diversity E-Commerce',
  flag: 'sa.svg',
  googleClientId: '966907037577-scrcgfr0egab9sd26f2a197uldqgngrf.apps.googleusercontent.com',
  version: '22.10.10.02'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
