

<div class="item-list">
    <div *ngIf="selectedCategory">
<!--        <h2>{{ selectedCategory }}</h2>-->
        <ul>
            <li *ngFor="let item of filteredItems" class="item-card">

                <img #image
                        (click)="openImageDialog(image.src, image.alt)"
                        [src]="item.image == '' ? 'assets/meals-images/img-placeholder.png' : item.image" alt="{{ item.name[selectedLanguage] }}"
                        class="item-image">
                <div class="item-details" [dir]="textDirection">
                    <h3>{{ item.name[selectedLanguage] }}</h3>
                    <p class="item-description">{{ item.description[selectedLanguage] }}</p>
                    <p class="item-price">{{ item.price  + ' JOD' }}</p>

<!--                    <br>-->
<!--                    <hr>-->
                    <div class="item-add-ons" *ngIf="item.addOns.length">
                        <h4>{{selectedLanguage === 'ar' ? 'الإضافات:' : 'Add-ons:'}}</h4>
                        <ul>
                            <li *ngFor="let addOn of item.addOns">
                                <span>{{ addOn.name[selectedLanguage] }} - {{ addOn.price + ' JOD.' }}</span>
                                <br>
                            </li>
                        </ul>
                    </div>
                    <br>
                    <mat-chip-list dir="ltr">
                        <mat-chip [class]="item.available ? 'chip-available' : 'chip-preorder'"
                                  removable="false">{{item.available ? 'Available' : 'Pre-order Required'}}
                        </mat-chip>
                        <div *ngIf="item.available">
                            <a style="cursor: pointer" href="https://www.talabat.com/jordan/restaurant/735755/the-dream-taste?aid=6646"
                               target="_blank">
                            <img style="width: 30px; height: 23px" src="assets/cropped-Talabat-New-Brand-Icon-2.png" alt="Talabat">
                            </a>
                            <a style="cursor: pointer" href="https://careem.me/TheDreamTaste"
                               target="_blank">
                            <img style="width: 30px; height: 23px" src="assets/icons8-careem-96.png" alt="Careem" matTooltip="Careem">
                            </a>
                        </div>
                    </mat-chip-list>
                    <br>
                </div>

            </li>
        </ul>
    </div>
    <div *ngIf="!selectedCategory">
        <p>Please select a category.</p>
    </div>
</div>






